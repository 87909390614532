import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../Utility/Button";
import { generalModelStatuses } from "../Utility/constants";
import FileUpload from "../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  SetJobObj,
  JobAdd,
  JobUpdate,
} from "../../redux/actions/Job/Job.actions";
import ReactQuill from "react-quill";
import { toastError } from "../Utility/ToastUtils";
function AddJob({ makeChange }) {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [caption, setCaption] = useState("");
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const statesObj = useSelector((jobs) => jobs.job.jobObj);
  const handleAddJob = () => {
    if (title == "") {
      toastError("Name is mandatory !");
      return;
    }
    if (category == "") {
      toastError("Category is mandatory !");
      return;
    }
    if (caption == "") {
      toastError("Caption is mandatory !");
      return;
    }

    if (description == "") {
      toastError("Description is mandatory !");
      return;
    }

    let obj = {
      title,
      status,
      category,
      caption,
      description,
    };
    console.log(obj, "category obj");

    if (statesObj?._id) {
      dispatch(JobUpdate(statesObj._id, obj));
      console.log("sdfndsfsdjhsdjkfsdhjkhfjksdhkjhkj");
      dispatch(SetJobObj(null));
      setTitle("");
      setDescription("");
      setCaption("");
      setCategory("");
    } else {
      dispatch(JobAdd(obj));
      setTitle("");
      setDescription("");
      setCaption("");
      setCategory("");
    }
  };

  useEffect(() => {
    if (statesObj) {
      setTitle(statesObj?.title);
      setStatus(statesObj?.status);
      setDescription(`${statesObj?.description ? statesObj?.description : ""}`);
      setCaption(statesObj?.caption);
      setCategory(statesObj?.category);
    }

    // return () => {
    //     dispatch(SETSTATEOBJ(null));
    // };
  }, [statesObj]);

  const handleSetImage = (value) => {
    setCaption(value);
  };

  return (
    <div className={makeChange ? "makeChange" : ""}>
      <form className="form row">
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Title <span className="red">*</span>
          </label>
          <input
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            type="text"
            placeholder="Enter Job Title"
            className="form-control"
          />
        </div>
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Category <span className="red">*</span>
          </label>
          <input
            value={category}
            placeholder="Enter Category"
            onChange={(event) => setCategory(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Caption <span className="red">*</span>
          </label>
          <input
            value={category}
            placeholder="Enter Job Caption"

            onChange={(event) => setCategory(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>
   
        <div className="col-12">
          <label>
            DESCRIPTION<span className="red">*</span>
          </label>
          <ReactQuill value={description} onChange={(e) => setDescription(e)} />
        </div>
        <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">Status</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                checked={status == generalModelStatuses.APPROVED}
                onClick={() => setStatus(generalModelStatuses.APPROVED)}
                type="radio"
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio1"
              >
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                checked={status == generalModelStatuses.DECLINED}
                onClick={() => setStatus(generalModelStatuses.DECLINED)}
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio2"
              >
                Inactive
              </label>
            </div>
          </div>
        </div>
        <div className="col-12">
          <CustomButton
            btntype="button"
            ClickEvent={handleAddJob}
            iconName="fa-solid fa-check"
            btnName="Save"
            isBtn
            small={makeChange ? true : false}
          />
        </div>
      </form>
    </div>
  );
}

export default AddJob;
